import React from 'react';

import { backgroundContainer, backgroundInsideContainer, video, backgroundOverlay } from './videoBackground.module.scss';
import blurredVideo from '../../images/video4.mp4';

export function VideoBackground({ position = 0 }) {
   return (
      <div className={backgroundContainer}>
         <div className={backgroundInsideContainer}>
            <video autoPlay muted loop className={video}>
               <source src={`${blurredVideo}#t=${position}`} type="video/mp4" />
            </video>
         </div>
         <div className={backgroundOverlay} />
      </div>
   );
}
