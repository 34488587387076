import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import logoDark from '../../images/logo_dark.svg';
import logoVture from '../../images/logo_vture.svg';

import * as footerClasses from './footer.module.scss';
import { SOCIALLINKS } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Footer() {
   const { t } = useTranslation('navigation');

   return (
      <footer className={footerClasses.footer}>
         <Container>
            <img src={logoDark} alt="VTURE I|O" height={30} className="mb-3" />

            <Row className="pb-3 gx-5">
               <Col sm={12} md={5}>
                  <div className={footerClasses.about}>{t('about')}</div>
               </Col>
               <Col xs={6} sm={6} md={3}>
                  <h5 className="roboto-regular">{t('platform')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/platform'}>
                           {t('general')}
                        </Link>
                     </li>
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/ingest'}>
                           {t('ingest')}
                        </Link>
                     </li>
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/transmit'}>
                           {t('transmit')}
                        </Link>
                     </li>
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/playout'}>
                           {t('playout')}
                        </Link>
                     </li>
                  </ul>
               </Col>
               <Col xs={6} sm={6} md={3}>
                  <h5 className="roboto-regular">{t('contact')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/contact'}>
                           {t('contact')}
                        </Link>
                     </li>
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/imprint'}>
                           {t('imprint')}
                        </Link>
                     </li>
                     <li>
                        <Link className={footerClasses.navigationItem} to={'/privacy'}>
                           {t('privacy')}
                        </Link>
                     </li>
                  </ul>
               </Col>
            </Row>
            <hr />
            <Row className="pt-4 pb-4 gx-3">
               <Col xs="auto">
                  <a href="https://www.vture.de">
                     <img src={logoVture} alt="VTURE I|O" height={23.5} style={{ opacity: 0.5 }} />
                  </a>
               </Col>
               <Col>
                  <div className={footerClasses.companyInfo}>
                     Software & Solutions
                     <br />
                     <a href="https://www.vture.de" style={{ textDecoration: 'none' }}>
                        www.vture.de
                     </a>
                  </div>
               </Col>
               <Col>
                  <div style={{ textAlign: 'right' }}>
                     <a href={SOCIALLINKS.LINKEDIN.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
                     </a>{' '}
                     <a href={SOCIALLINKS.TWITTER.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'twitter-square']} size="2x" />
                     </a>
                  </div>
               </Col>
            </Row>
            <div className={footerClasses.infotextLeft}>&copy; {new Date().getFullYear()} VTURE GmbH. All rights reserved</div>
         </Container>
      </footer>
   );
}
