import React, { useCallback, useState } from 'react';

import { Row, Col, Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationScrollIndicator } from './NavigationScrollIndicator';
import { useI18next, Link, useTranslation } from 'gatsby-plugin-react-i18next';

import * as navigationClass from './navigation.module.scss';

import logoDark from '../../images/logo_dark.svg';
import classNames from 'classnames';

export function Navigation({ location }) {
   const { t } = useTranslation('navigation');
   const { pathname } = location;
   const { language, changeLanguage } = useI18next();

   const [expanded, setExpanded] = useState(false);
   const hideNavbar = useCallback(() => setExpanded(false), []);

   const ExtendedLink = ({ to }) => (
      <Link
         className={classNames(navigationClass.navigationItem, {
            [navigationClass.active]: pathname.includes(to),
         })}
         to={`/${to}`}
         onClick={hideNavbar}
      >
         {t(to)}
      </Link>
   );

   return (
      <header className={navigationClass.header}>
         <Container>
            <Row>
               <Col>
                  <Navbar collapseOnSelect expanded={expanded} onToggle={setExpanded} expand="md" className={classnames('sticky-top')} variant="dark">
                     <Navbar.Brand>
                        <Link to={'/'}>
                           <img src={logoDark} alt="VTURE I|O" height={30} />
                        </Link>
                     </Navbar.Brand>
                     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                     <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ms-auto">
                           <ExtendedLink to={'platform'} />
                           <ExtendedLink to={'ingest'} />
                           <ExtendedLink to={'transmit'} />
                           <ExtendedLink to={'playout'} />
                           <ExtendedLink to={'contact'} />
                        </Nav>
                        <Nav>
                           <NavDropdown
                              size="sm"
                              title={
                                 <>
                                    <FontAwesomeIcon icon="globe" className="me-2" />
                                    {language.toUpperCase()}
                                 </>
                              }
                           >
                              <NavDropdown.Item active={language === 'de' ? true : false} onClick={() => changeLanguage('de')}>
                                 {t('german')}
                              </NavDropdown.Item>
                              <NavDropdown.Item active={language === 'en' ? true : false} onClick={() => changeLanguage('en')}>
                                 {t('english')}
                              </NavDropdown.Item>
                           </NavDropdown>
                        </Nav>
                     </Navbar.Collapse>
                  </Navbar>
               </Col>
            </Row>
         </Container>
         <NavigationScrollIndicator />
      </header>
   );
}
