import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useScrollPosition } from '../helper/useScrollPosition';

import { container } from './navigationScrollIndicator.module.scss';
import { useI18next } from 'gatsby-plugin-react-i18next';

const backgroundColorsMap = {
   '/transmit': 'bg-vture-light-cyan',
   '/playout': 'bg-vture-orange',
   '/ingest': 'bg-vture-yellow',
   '/platform': 'bg-vture-dark-cyan',
   '/about': 'bg-vture-dark-cyan',
};

function getCleanedPath(path) {
   if (!path) {
      return;
   }
   return path.replace(/\/$/, '');
}

export function NavigationScrollIndicator(props) {
   const [state] = useScrollPosition();
   const { originalPath } = useI18next();

   const backgroundColor = useMemo(() => {
      const color = backgroundColorsMap[getCleanedPath(originalPath)];
      if (!color) {
         return 'bg-light';
      }
      return color;
   }, [originalPath]);

   return <div className={classnames(container, backgroundColor)} style={{ width: `${state.value}%` }}></div>;
}
