export const SOCIALLINKS = {
   LINKEDIN: {
      VTURE: 'https://de.linkedin.com/company/vturegmbh/',
   },
   XING: {
      VTURE: 'https://xing.com/companies/vturegmbh/',
   },
   TWITTER: {
      VTURE: 'https://twitter.com/vture_media/',
   },
};

export const backgroundIntentMap = {
   'vture-yellow': 'bg-vture-yellow',
   'vture-orange': 'bg-vture-orange',
   'vture-light-cyan': 'bg-vture-light-cyan',
   'vture-dark-cyan': 'bg-vture-dark-cyan',
   light: 'bg-light',
};

export const textIntentMap = {
   'vture-yellow': 'text-vture-blue',
   'vture-orange': 'text-vture-blue',
   'vture-light-cyan': 'text-vture-blue',
   'vture-dark-cyan': 'text-light',
   light: 'text-vture-blue',
};

export const outlineIntentMap = {
   'vture-yellow': 'outline-vture-blue',
   'vture-orange': 'outline-vture-blue',
   'vture-light-cyan': 'outline-vture-blue',
   'vture-dark-cyan': 'outline-light',
   light: 'outline-vture-blue',
};
