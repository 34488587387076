import { library } from '@fortawesome/fontawesome-svg-core';
import {
   faComments,
   faExpandArrowsAlt,
   faCogs,
   faSmile,
   faFrown,
   faSadCry,
   faLevelUpAlt,
   faAngleDown,
   faCopy,
   faCheckCircle,
   faBug,
   faShieldAlt,
   faPlug,
   faShippingFast,
   faCheckSquare,
   faClipboardList,
   faGlobe,
   faArrowRight,
   faChevronDown,
   faRocket,
   faFileVideo,
   faKeyboard,
   faGraduationCap,
   faMicrochip,
   faCubes,
   faTh,
   faNetworkWired,
   faSlidersH,
   faStream,
} from '@fortawesome/free-solid-svg-icons';

import { faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

library.add(
   faComments,
   faExpandArrowsAlt,
   faCogs,
   faSmile,
   faFrown,
   faSadCry,
   faLevelUpAlt,
   faAngleDown,
   faCopy,
   faCheckCircle,
   faBug,
   faShieldAlt,
   faPlug,
   faShippingFast,
   faCheckSquare,
   faClipboardList,
   faGlobe,
   faArrowRight,
   faChevronDown,
   faRocket,
   faFileVideo,
   faKeyboard,
   faGraduationCap,
   faMicrochip,
   faCubes,
   faTh,
   faLinkedin,
   faTwitterSquare,
   faNetworkWired,
   faSlidersH,
   faStream,
);
