import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../global/Footer';
import { Navigation } from '../global/Navigation';
import ScrollUpButton from '../helper/ScrollUpButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../icons';
import '../../scss/style.scss';
import { VideoBackground } from '../global/VideoBackground';

const Layout = ({ children, location }) => {
   return (
      <>
         <Helmet>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="dns-prefetch" href="//fonts.googleapis.com" />
            <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700&display=swap" rel="stylesheet" />{' '}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#00aba9" />
            <meta property="og:site_name" content="VTURE I|O - Die modulare Media Encoding- und Decoding Plattform." />
            <meta property="og:title" content="VTURE I|O - Die modulare Media Encoding- und Decoding Plattform." />
            <meta
               property="og:description"
               content="Eine flexibel anpassbare Lösung für die Aufzeichnung, Verarbeitung und Wiedergabe von Mediendateien aller Art."
            />
            <meta property="og:url" content="https://www.vture.io/" />
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content="VTURE I|O - Die modulare Media Encoding- und Decoding Plattform." />
            <meta
               name="twitter:description"
               content="Eine flexibel anpassbare Lösung für die Aufzeichnung, Verarbeitung und Wiedergabe von Mediendateien aller Art."
            />
            <meta itemprop="name" content="VTURE I|O - Die modulare Media Encoding- und Decoding Plattform." />
            <meta
               itemprop="description"
               content="Eine flexibel anpassbare Lösung für die Aufzeichnung, Verarbeitung und Wiedergabe von Mediendateien aller Art."
            />
         </Helmet>
         <div className="main">
            <Navigation location={location} />
            <VideoBackground />
            <div className="main-container">{children}</div>
            <Footer />
         </div>
         <ScrollUpButton ShowAtPosition={160}>
            <div>
               <span className="roboto-slab-bold">
                  <FontAwesomeIcon icon="level-up-alt" className="me-2" />
                  TOP
               </span>
            </div>
         </ScrollUpButton>
      </>
   );
};

export default Layout;
